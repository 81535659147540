<template>
    <div>
        <PageLoader :isLoading="isLoading" />
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>{{ singleproduct.title }}</h2>
                        <h5>{{ singleproduct.name }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12">
                        <ImageGallery
                            :defaultImage="activeImage"
                            :images="singleproduct.image + '|' + singleproduct.multi_img"
                        />
                        <div class="profile-edit" style="margin-top: 30px; font-size: 0.8em;">
                            <h2><i class="fa fa-bars"></i> PRODUCT DETAILS</h2>
                            <div class="row px-0">
                                <div
                                    class="col-sm-2"
                                    style="background-color:#f1f1f1;padding: 10px;"
                                >
                                    Title
                                </div>
                                <div
                                    class="col-sm-4"
                                    style="background-color:#fafafa;padding: 10px;"
                                >
                                    {{ singleproduct.title }}
                                </div>
                                <div
                                    class="col-sm-2"
                                    style="background-color:#f1f1f1;padding: 10px;"
                                >
                                    Place
                                </div>
                                <div
                                    class="col-sm-4"
                                    style="background-color:#fafafa;padding: 10px;"
                                >
                                    {{ singleproduct.address }}
                                </div>
                                <div
                                    class="col-sm-2"
                                    style="background-color:#f1f1f1;padding: 10px;"
                                >
                                    State
                                </div>
                                <div
                                    class="col-sm-4"
                                    style="background-color:#fafafa;padding: 10px;"
                                >
                                    {{ singleproduct.state && singleproduct.state.state_name }}
                                </div>
                                <div
                                    class="col-sm-2"
                                    style="background-color:#f1f1f1;padding: 10px;"
                                >
                                    City
                                </div>
                                <div
                                    class="col-sm-4"
                                    style="background-color:#fafafa;padding: 10px;"
                                >
                                    {{ singleproduct.city && singleproduct.city.city_name }}
                                </div>
                            </div>
                            <div class="row">
                                <!-- <div
                  class="col-sm-2"
                  style="background-color:#f1f1f1;padding: 10px;"
                >
                  Color
                </div>
                <div
                  class="col-sm-4"
                  style="background-color:#fafafa;padding: 10px;"
                >
                  Color Name
                </div> -->
                                <!-- <div
                                    class="col-sm-2"
                                    style="background-color:#f1f1f1;padding: 10px;"
                                >
                                    Rating
                                </div>
                                <div
                                    class="col-sm-4 vacancies_box_rating"
                                    style="background-color: rgb(250, 250, 250);"
                                >
                                    <i
                                        class="fa fa-star"
                                        style="background-color: rgb(250, 250, 250);padding: 5px;color: #c1c1c1;"
                                    ></i>
                                    <i
                                        class="fa fa-star"
                                        style="background-color: rgb(250, 250, 250);padding: 5px;color: #c1c1c1;"
                                    ></i>
                                    <i
                                        class="fa fa-star"
                                        style="background-color: rgb(250, 250, 250);padding: 5px;color: #c1c1c1;"
                                    ></i>
                                    <i
                                        class="fa fa-star"
                                        style="background-color: rgb(250, 250, 250);padding: 5px;color: #c1c1c1;"
                                    ></i>
                                    <i
                                        class="fa fa-star"
                                        style="background-color: rgb(250, 250, 250);padding: 5px;color: #c1c1c1;"
                                    ></i>
                                </div> -->
                            </div>
                            <div class="col-sm-12" style="margin-top: 30px;">
                                <h2>DESCRIPTION</h2>
                                <div>
                                    <p v-if="singleproduct.description != ''">
                                        {{ singleproduct.description }}
                                    </p>
                                    <p v-else>
                                        No Any Description.
                                    </p>
                                    <h2></h2>

                                    <!-- <div
                                        class="
                                                blog_article_content_emoji
                                                blog_article_content_social
                                            "
                                    >
                                        <span>Share</span>
                                        <div
                                            class="
                                                    addthis_inline_share_toolbox
                                                "
                                        ></div>
                                    </div> -->
                                    <div class="row" v-show="false">
                                        <div class="col-sm-12">
                                            <ul class="left-profile-link" style="float:right">
                                                <li
                                                    style="display: inline;background-color: white;"
                                                >
                                                    <i
                                                        class="fa fa-share-alt"
                                                        style="background-color: white;color:black;width: 30px;"
                                                    ></i>
                                                    <h3
                                                        style="display: inline;margin-right: 10px;background-color: white;padding: 0px;"
                                                    >
                                                        Share:
                                                    </h3>
                                                </li>
                                                <li></li>
                                                <li
                                                    style="background-color: #ffffff;display: inline;"
                                                >
                                                    <a href="#"
                                                        ><i
                                                            class="fa fa-facebook-f"
                                                            style="width: 46px;"
                                                        ></i
                                                    ></a>
                                                </li>
                                                <li
                                                    style="background-color: #ffffff;display: inline;"
                                                >
                                                    <a href="#"
                                                        ><i
                                                            class="fa fa-google-plus"
                                                            style="width: 46px;"
                                                        ></i
                                                    ></a>
                                                </li>
                                                <li
                                                    style="background-color: #ffffff;display: inline;"
                                                >
                                                    <a href="#"
                                                        ><i
                                                            class="fa fa-pinterest-p"
                                                            style="width: 46px;"
                                                        ></i
                                                    ></a>
                                                </li>
                                                <li
                                                    style="background-color: #ffffff;display: inline;"
                                                >
                                                    <a href="#"
                                                        ><i
                                                            class="fa fa-linkedin"
                                                            style="width: 46px;"
                                                        ></i
                                                    ></a>
                                                </li>
                                                <li
                                                    style="background-color: #ffffff;display: inline;"
                                                >
                                                    <a href="#"
                                                        ><i
                                                            class="fa fa-instagram"
                                                            style="width: 46px;"
                                                        ></i
                                                    ></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="profile-edit-left" v-if="!isLogged">
                            <button
                                type="submit"
                                class="primary-button registerbtn"
                                @click="register"
                            >
                                ENQUIRE NOW
                            </button>
                        </div>
                        <div class="profile-edit-left" style="margin-top: 30px;" v-if="isLogged">
                            <h2>
                                {{ processingForm ? "Please wait..." : "ENQUIRE NOW" }}
                            </h2>
                            <div v-if="!isMaterialTester && !enquire">
                                <form
                                    class="about-me-profile"
                                    ref="reset"
                                    v-if="user_id.user_id != auth_user_id"
                                >
                                    <input
                                        type="hidden"
                                        name="user_id"
                                        id="user_id"
                                        v-model="singleproduct.user_id"
                                    />
                                    <div class="form-group row">
                                        <div class="col-6 mb-3">
                                            <input
                                                type="text"
                                                placeholder="Full Name"
                                                v-model="user_name"
                                                id="name"
                                                :disabled="enquire && enquire.product_id == id"
                                                readonly
                                                class="form-control"
                                            />
                                            <span class="status small" id="name-status"></span>
                                        </div>
                                        <div class="col-6  mb-3">
                                            <input
                                                type="text"
                                                placeholder="Email"
                                                v-model="user_email"
                                                :disabled="enquire && enquire.product_id == id"
                                                id="email"
                                                readonly
                                                class="form-control"
                                            />
                                            <span class="status small" id="email-status"></span>
                                        </div>

                                        <div class="col-6">
                                            <input
                                                type="text"
                                                placeholder="Subject"
                                                v-model="subject"
                                                required
                                                :disabled="enquire && enquire.product_id == id"
                                                class="form-control"
                                            />
                                            <span class="status small" id="subject-status"></span>
                                        </div>

                                        <div class="col-6">
                                            <textarea
                                                placeholder="Type your message"
                                                v-model="message"
                                                :disabled="enquire && enquire.product_id == id"
                                                required
                                                class="form-control"
                                            ></textarea>
                                            <span class="status small" id="message-status"></span>
                                        </div>

                                        <!--              <p-->
                                        <!--                style="font-size:18px;width: 48%;-->
                                        <!--margin-top: 20px;"-->
                                        <!--              >-->
                                        <!--                Human test. Please input the result.of 5+3=?-->
                                        <!--              </p>-->
                                        <!--<input-->
                                        <!--  type="text"-->
                                        <!--  placeholder="Answer"-->
                                        <!--  v-model="answer"-->
                                        <!--  :disabled="enquire.auth_id ? '' : disabled"-->
                                        <!--  required-->
                                        <!--/>-->
                                    </div>
                                    <!--<span class="status small" id="answer-status"></span>-->
                                    <!--<div v-if="ress && ress.answer" class="text-danger">-->
                                    <!--  {{ ress.answer[0] }}-->
                                    <!--</div>-->

                                    <button
                                        type="submit"
                                        :disabled="enquire && enquire.product_id == id"
                                        class="registerbtn primary-button"
                                        @click="update"
                                    >
                                        ENQUIRE NOW
                                    </button>
                                    <!-- <button type="submit" class="registerbtn">
                  REQUEST A ORDER
                </button> -->
                                </form>
                                <p v-else>
                                    Sorry! You Can not Enquire about your own products!
                                </p>
                            </div>
                            <p v-if="isMaterialTester">
                                Sorry! You Can not Enquire as a material inspector!
                            </p>
                            <div v-if="enquire" class="mt-3">
                                You have already started conversation with the seller.
                                <button
                                    class="primary-button"
                                    @click="
                                        $router.push({
                                            name: 'enquire_details',
                                            params: { id: enquire && enquire.ticket_id },
                                        })
                                    "
                                >
                                    Go to Messages
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12">
                        <DataFilter
                            v-if="!isLoading"
                            :noScrollTop="true"
                            :showLoader="false"
                            filterType="redirect"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="https://unpkg.com/vuejs-paginate@latest"></script>
<script src="https://unpkg.com/vuejs-paginate@0.9.0"></script>

<script>
import axios from "axios"

import DataFilter from "./utils/Filter.vue"
import ImageGallery from "./utils/ImageGallery.vue"
import EnlargeableImage from "@diracleo/vue-enlargeable-image"
import scrollTop from "./utils/scrollTop"
import request from "../apis/request"
import { mapState, mapActions, mapGetters } from "vuex"
import PageLoader from "./Loader.vue"

export default {
    name: "surplus",
    delimiters: ["${", "}"], // Avoid Twig conflicts

    components: {
        // vueDropzone: vue2Dropzone,

        EnlargeableImage,
        DataFilter,
        ImageGallery,
        PageLoader,
    },

    data() {
        return {
            // imgsrc: "http://103.212.120.205/~dev/public/admin_profile/",
            //   imgsrc: "http://localhost/backend/public/admin_profile/",
            imgsrc: axios.defaults.uploadURL,

            filelist: [],
            activeImage: "",
            ress: {},
            // number: 0,

            id: this.$route.params.id,
            currentId: null,
            categores: null,
            subcategores: null,
            subbcategory: null,
            searchcat: "",
            singleproduct: "",
            name: "",
            email: "",
            message: "",
            subject: "",
            answer: "",
            enquire: "",
            auth_user_id: "",
            user_name: "",
            user_email: "",
            user_id: "",
            user_role: "",
            processingForm: false,
            isLoading: false,
            action: "/surplus",
        }
    },

    mounted() {
        scrollTop()
        this.auth_user_id = this.user.id
        this.user_role = this.user.role_id
        this.user_name = this.user.name
        this.user_email = this.user.email

        // script for share buttons

        let addThisScript = document.createElement("script")
        addThisScript.setAttribute(
            "src",
            "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-608a9e58897e109f"
        )
        document.head.appendChild(addThisScript)

        // end of script
    },
    computed: {
        ...mapState("auth", {
            user: "user",
            isLogged: "authenticated",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
    },
    watch: {},
    methods: {
        register: function(e) {
            e.preventDefault()
            this.$toasted.error("Please login to continue!")
        },

        update: function(e) {
            e.preventDefault()

            if (this.processingForm) return

            if (this.subject.length == 0) {
                document.getElementById("subject-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            }
            if (this.message.length == 0) {
                document.getElementById("message-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            }
            //   if (this.answer.length == 0) {
            //     document.getElementById("answer-status").innerHTML =
            //       "<span class='warning' style='color: red;'>You need to fill out this form.</span>";
            //   }

            if (
                !(
                    (this.subject.length > 0 && this.message.length > 0)
                    //   this.answer.length > 0
                )
            )
                return

            this.ress = {}

            const form = new FormData()
            form.append("name", this.user.name)
            form.append("email", this.user.email)
            form.append("subject", this.subject)
            form.append("message", this.message)
            //   form.append("answer", this.answer);
            form.append("product_id", this.id)
            form.append("user_id", this.singleproduct.user_id)
            //console.log(this.image);
            this.processingForm = true

            request
                .post("/enquire", form)
                .then((res) => {
                    //   window.localStorage.setItem("isLogged", true);
                    //   window.localStorage.setItem("user", JSON.stringify(res.data));
                    //console.log("localstorage", JSON.parse(window.localStorage.getItem('user')));
                    // this.$router.push({ name: "dashboard" });
                    //  console.log("ok")
                    this.$refs.reset.reset(),
                        this.$toasted.success("Order request sent successfully!", {
                            keepOnHover: true,
                            iconPack: "fontawesome",
                            icon: "check",
                            theme: "toasted-primary",
                            // timerProgressBar: true,
                            duration: 3000,
                        })

                    this.$router.push({
                        name: "enquire_details",
                        params: { id: res.data.result.ticket_id },
                    })
                    this.processingForm = false

                    //   alert('Profile Update successfully!');
                })
                .catch((res) => {
                    //alert('err');
                    if (res.response.status === 422) {
                        this.ress = res.response.data.errors || {}
                    }
                    this.processingForm = false
                    return res
                })
        },

        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let msg
            if (res === false)
                msg = "<span class='warning' style='color: red;'>Email is not valid yet.</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("email-status").innerHTML = msg
            return res
        },

        clickCallback(pageNum) {
            console.log(pageNum)
        },
    },

    async created() {
        this.isLoading = true
        try {
            let response = await request.get("/single-product/" + this.id)
            this.singleproduct = response.data
            this.activeImage = this.singleproduct.image
            response = await request.get("/user-single-product/" + this.id)
            this.user_id = response.data
            console.log("getting enquire result")

            response = await request.get("/get-enquire/" + this.id)
            this.enquire = response.data.result
            console.log("received enquire result", "setting loader to false")

            this.isLoading = false

            // response = await request.get("/categories")
            // this.categores = response.data
            // response = await request.get("/category")
            // this.subbcategory = response.data
        } catch (err) {
            console.log("error in loading categories", err)
            this.isLoading = false
        }
        this.isLoading = false
    },
    beforeDestroy() {
        // this.isLoading = true
        console.log("single product unmounting")
    },
}
</script>

<style>
select {
    font-family: "FontAwesome", "sans-serif";
}
input[type="range"] {
    -webkit-appearance: none;
    margin: 20px 0;
    width: 100%;
}
input[type="range"]:focus {
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: #ea6a69;
    border-radius: 25px;
}
input[type="range"]::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ea6a69;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ea6a69;
}
.range-wrap {
    /*width: 500px;*/
    position: relative;
}
.range-value {
    position: absolute;
    top: -50%;
}
.range-value span {
    width: 30px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #ea6a69;
    color: #fff;
    font-size: 12px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
}
.range-value span:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid #ea6a69;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
}
[v-cloak] {
    display: none;
}
.pagination {
    /* display: inline-block; */
    margin: 30px 100px;
}
.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    /* margin: 0 4px; */
}
/* .pagination a.active {
  background-color: #8c0526;
  color: white;
  border: 1px solid #4caf50;
} */
.pagination a:hover:not(.active) {
    background-color: #8c0526;
}
.page-item.active a {
    color: white;
    background-color: #8c0526;
}

.enlargeable-image .enlargeable-image-slot {
    display: inline-block;
}
.enlargeable-image .enlargeable-image-slot > img {
    max-width: 100%;
}
.enlargeable-image > .enlargeable-image-slot {
    max-width: 100%;
    max-height: 100%;
    cursor: zoom-in;
}
.enlargeable-image > .enlargeable-image-slot.active {
    opacity: 0.3;
    filter: grayscale(100%);
}
.enlargeable-image .enlargeable-image-full {
    cursor: zoom-out;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2000;
    display: none;
}
.enlargeable-image .enlargeable-image-full > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.enlargeable-image .enlargeable-image-full.enlarging {
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: zoom-out;
}
.enlargeable-image .enlargeable-image-full.delarging {
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: zoom-in;
}
.enlargeable-image .enlargeable-image-full.enlarged {
    display: flex;
}
</style>
