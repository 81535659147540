<template>
    <div class="image-gallery row">
        <div class="image-gallery-list">
            <div
                :class="['image-gallery-list-item', { active: img === activeImage }]"
                v-for="(img, index) in images.split('|')"
                :key="index"
                @click="activeImage = img"
            >
                <div class="active-gallery-image-overlay" v-show="img === activeImage"></div>
                <ImageItem v-if="img" :src="imgsrc + img" style="max-width: 100%;margin:0px;" />
                <ImageItem
                    v-else
                    class="categorie_image"
                    src="http://cliquecities.com/assets/no-image-e3699ae23f866f6cbdf8ba2443ee5c4e.jpg"
                    style="max-width: 100%;margin:0px;"
                />
            </div>
        </div>
        <div class="image-gallery-large-container">
            <enlargeable-image :src="imgsrc + activeImage" :src_large="imgsrc + activeImage">
                <ImageItem :src="imgsrc + activeImage" class="img-fluid large-image-view" />
            </enlargeable-image>
        </div>
    </div>
</template>

<script>
import EnlargeableImage from "@diracleo/vue-enlargeable-image"
import ImageItem from "./ImageItem.vue"
import axios from "axios"

export default {
    components: { EnlargeableImage, ImageItem },
    props: {
        images: {
            type: Array,
            default: () => [],
        },
        defaultImage: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            // imgsrc: "http://103.212.120.205/~dev/public/admin_profile/",
            imgsrc: axios.defaults.uploadURL,
            activeImage: "",
        }
    },
    watch: {
        defaultImage(e) {
            this.activeImage = e
        },
    },
}
</script>

<style lang="scss" scoped>
$color: rgb(198, 108, 108);
$colorLight: rgb(255, 215, 215);
.image-gallery {
    display: flex;

    .image-gallery-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 5px;

        .image-gallery-list-item {
            cursor: pointer;
            border: 1px solid $color;
            padding: 3px;
            margin-bottom: 10px;
            border-radius: 5px;
            position: relative;
            min-height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;

            .active-gallery-image-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($color, 0.4);
            }

            &.active {
                // background: black;
                filter: brightness(110%);
            }
        }
    }
    .image-gallery-large-container {
        flex: 5;
        border-radius: 5px;
        background: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        .large-image-view {
            max-width: 100%;
            width: 400px;
            height: 350px;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 576px) {
    .image-gallery {
        padding: 20px;
        flex-direction: column;
        .image-gallery-list {
            // width: 10000px;
            order: 2;
            display: flex;
            flex-flow: row wrap;
            margin: 10px 0;
            .image-gallery-list-item {
                margin-right: 10px;
            }
            img {
                height: 80px;
            }
        }
    }
}
</style>
